export const APP_NAME = 'Haze'
export const PERSIST_STORE_NAME = 'haze_admin'
export const REDIRECT_URL_KEY = 'redirectUrl'

export const ELECTRICITY_COLOR = 'sky'
export const GAZ_COLOR = 'lime'


export const CLIENTS_TYPES = {
    ENTERPRISE: 1,
    PARTICULAR: 2
}

export const profileOptions = [
    { value: "C5", label: "C5", color: "bg-blue-600" },
    { value: "C4", label: "C4", color: "bg-yellow-300" },
    { value: "C3", label: "C3", color: "bg-green-600" },
    { value: "C2", label: "C2", color: "bg-green-600" },
  ];
  
export const gazProfileOptions = [
  { value: "P011", label: "P011", color: "#36B37E" },
  { value: "P012", label: "P012", color: "#FF5630" },
  { value: "P013", label: "P013", color: "#FF5630" },
  { value: "P014", label: "P014", color: "#FF5630" },
  { value: "P015", label: "P015", color: "#FF5630" },
  { value: "P016", label: "P016", color: "#FF5630" },
  { value: "P017", label: "P017", color: "#FF5630" },
  { value: "P018", label: "P018", color: "#FF5630" },
  { value: "P019", label: "P019", color: "#FF5630" },
];
  
export const gazDeliveryRatesOptions = [
  { value: "T1", label: "T1", color: "#36B37E" },
  { value: "T2", label: "T2", color: "#FF5630" },
  { value: "T3", label: "T3", color: "#FF5630" },
  { value: "T4", label: "T4", color: "#FF5630" }

];
  
export const useOptions = [
    { value: "CU", label: "CU" },
    { value: "MU", label: "MU"},
    { value: "LU", label: "LU"}
  ];
  