const appConfig = {
    apiPrefix: `${process.env.REACT_APP_API_URL}`,
    prodApiPrefix: `${process.env.REACT_APP_API_URL}`,
    authProdApiPrefix: `${process.env.REACT_APP_AUTH_API_URL}`,
    authenticatedEntryPath: '/pages/dashboard/summary',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'fr',
    enableMock: false,
    entrepriseSearchApi : 'https://recherche-entreprises.api.gouv.fr/search'
}

export default appConfig