import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiCreateSupplier, apiDeleteSupplier, apiGetSupplier, apiGetSuppliers, apiPutSupplier } from 'services/SuppliersService'



export const getSuppliers = createAsyncThunk('shared/supplier/getSuppliers', async (data) => {
    const response = await apiGetSuppliers(data)
    return response.data
})

export const getSupplier = createAsyncThunk('shared/supplier/getSupplier', async (data) => {
    const response = await apiGetSupplier(data)
    return response.data
})

export const createSupplier =  async (data) => {
    const response = await apiCreateSupplier(data)
    return response.data
}

export const updateSupplier =  async (data,supplierId) => {
    const response = await apiPutSupplier(data,supplierId)
    return response.data
}

export const deleteSupplier = createAsyncThunk('shared/supplier/deleteSupplier',async (data) => {
    const response = await apiDeleteSupplier(data)
    return response.data
})

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}
export const initialState = {
    suppliers: [],
    partners: [],
    others: [],
    loading: false,
    tableData: initialTableData,
    current: null
}

export const supplierSlice = createSlice({
	name: 'shared/supplier',
	initialState,
	reducers: {
        
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
	},
    extraReducers: {
        [getSuppliers.fulfilled]: (state, action) => {
            state.suppliers = action.payload;
            state.partners = action.payload.filter(p => p.s_partnership == 1);
            state.others = action.payload.filter(p => p.s_partnership == 0);
            state.loading = false
        },
        [getSuppliers.pending]: (state) => {
            state.loading = true
        },
        [getSupplier.fulfilled]: (state, action) => {
            state.current = action.payload
            state.loading = false
        },
        [getSupplier.pending]: (state) => {
            state.loading = true
        }
    }
})

export const { setTableData,setFilterData, setSortedColumn  } = supplierSlice.actions

export default supplierSlice.reducer