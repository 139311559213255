import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiAddEvent, apiDeleteEvent, apiGetAllEvents, apiUpdateEvent, apiUpdateFieldsEvent } from 'services/agenda/EventsService'

export const fetchEvents = createAsyncThunk('shared/events/fetchEvents', async () => {
    const response = await apiGetAllEvents();
  
    return response.data
  })
  
  // ** Add Event
  export const createEvent = createAsyncThunk('shared/events/createEvent', async (datas, { dispatch }) => {
    const response = await apiAddEvent(datas);
    await dispatch(fetchEvents())
  
    return response.data.event
  })
  
  // ** Update Event
  export const updateEvent = createAsyncThunk('shared/events/updateEvent', async (datas , { dispatch }) => {
    const response = await apiUpdateEvent(datas)
    
    await dispatch(fetchEvents())
  
    return response.data.event
  })

  export const updateEventFields = createAsyncThunk('shared/events/updateEventFields', async (datas , { dispatch }) => {
    const response = await apiUpdateFieldsEvent(datas)
    
    await dispatch(fetchEvents())
  
    return response.data.event
  })
  
  // ** Delete Event
  export const deleteEvent = createAsyncThunk('shared/events/deleteEvent', async (id, { dispatch }) => {
    const response = await apiDeleteEvent(id);
    await dispatch(fetchEvents())
  
    return response.data
  })

export const initialState = {
    events: [],
    loadingEvents: false,
}

export const eventsSlice = createSlice({
    name: 'shared/events',
    initialState,
    reducers: {
        // setCurrentRouteKey: (state, action) => {
        //     state.currentRouteKey = action.payload
        // },
    },
    extraReducers: {
        [fetchEvents.fulfilled]: (state, action) => {
          let events = action.payload;
          state.events = events;
          state.loadingEvents = false;
          console.log("GET ALL event ",events);
          },
          [fetchEvents.pending]: (state) => {
            state.loadingEvents = true;
          },
    }
})

export const { } = eventsSlice.actions

export default eventsSlice.reducer
