import { useState, useEffect } from "react";

const useUserLocation = () => {
  const [ip, setIp] = useState(null);
  const [city, setCity] = useState(null);
  const [error, setError] = useState(null);
  const [location, setLocation] = useState(null);
  useEffect(() => {
    const fetchIpAndLocation = async () => {
      try {
        // Fetch the IP address
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        setIp(ipData.ip);

        // Fetch the city based on the IP address
        const locationResponse = await fetch(
          `https://ipwhois.app/json/${ipData.ip}`
        );
        const locationData = await locationResponse.json();

        if (locationData.success == true) {
          setLocation(locationData);
          setCity(locationData.city);
        } else {
          setError("Error fetching location");
        }
      } catch (error) {
        setError("Error fetching IP or location");
        console.error("Error fetching IP or location:", error);
      }
    };

    fetchIpAndLocation();
  }, []);

  return { ip, location, city, error };
};

export default useUserLocation;
