import ApiService from "./ApiService"

export async function apiGetCrmDashboardData (data) {
    return ApiService.fetchData({
        url: '/crm/dashboard',
        method: 'get',
        data
    })
}

export async function apiGetSuppliers(params) {
    return ApiService.fetchData({
        url: '/suppliers',
        method: 'get',
        params: params
    })
}

export async function apiGetSuppliersTable(params) {
    return ApiService.fetchData({
        url: '/suppliers/query',
        method: 'get',
        params: params
    })
}

export async function apiGetSupplier (params) {
    return ApiService.fetchData({
        url: '/suppliers/' + params.id,
        method: 'get',
        params: params
    })

}

export async function apiCreateSupplier (data) {
    return ApiService.fetchData({
        url: '/suppliers/new',
        method: 'post',
        data
    })
}



export async function apiPutSupplier (data,supplierId) {
    return ApiService.fetchData({
        url: '/suppliers/' + supplierId + '/update', 
        method: 'post',
        data
    })
}



export async function apiDeleteSupplier (data) {
    return ApiService.fetchData({
        url: '/suppliers/' + data.id + '/delete', 
        method: 'delete',
        data
    })
}

